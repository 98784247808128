import type { LoginRequest, SessionBase } from '@asi/auth/types-auth';

export namespace Auth {
  export class CheckSession {
    static type = '[Auth] CheckSession';
  }

  export class LoginWithCredentials {
    static type = '[Auth] LoginWithCredentials';

    constructor(
      public credentials: LoginRequest,
      public options?: { redirectUrl?: string; ignoreRedirect?: boolean }
    ) {}
  }

  export class LoginWithToken {
    static type = '[Auth] LoginWithToken';

    constructor(
      public token: string,
      public redirectUrl?: string,
      public reload?: boolean
    ) {}
  }

  export class LoginSuccess {
    static type = '[Auth] LoginSuccess';

    constructor(public session: SessionBase) {}
  }

  export class LoginError {
    static type = '[Auth] LoginError';

    constructor(public error: Error) {}
  }

  export class RefreshSessionSuccess {
    static type = '[Auth] RefreshSessionSuccess';

    constructor(public session: SessionBase) {}
  }

  export class SessionSetUp {
    static type = '[Auth] SessionSetUp';
  }

  export class Logout {
    static type = '[Auth] Logout';

    constructor(
      public redirectUrl?: string,
      public is401?: boolean,
      public autoLogOff?: boolean
    ) {}
  }

  export class LogoutComplete {
    static readonly type = '[Auth] Logout Complete';
  }

  export class SyncUserProps<T> {
    static type = '[Auth] Sync User Props';

    constructor(public props: Partial<T>) {}
  }

  export class SyncUserProfile {
    static type = '[Auth] Sync Session';
  }

  export class UpdateSessionPermissions {
    static readonly type = '[Auth] Update Session Permissions';

    // Note: also pick `password_reset_required`.
    constructor(readonly session: Pick<SessionBase, 'need_consent'>) {}
  }

  export class ReloadToUpdateUserContext {
    static type = `[Auth] ReloadToUpdateUserContext`;
  }

  export class ClearSession {
    static type = '[Auth] Clear Session';
  }
}
