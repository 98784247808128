/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line no-restricted-syntax
import { inject, Injectable, InjectionToken } from '@angular/core';

import { COS_DATE_LOCALE, DateAdapter, DateUnit } from '@cosmos/util-datetime';
import {
  dayjs,
  getTimezoneAbbreviation,
  parseAndSetTime,
  type ConfigType,
  type Dayjs,
} from '@cosmos/util-dayjs';

export interface DayJsDateAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * @default false
   */
  useUtc?: boolean;
  strict?: boolean;
}

/** InjectionToken for Dayjs date adapter to configure options. */
export const COS_DAYJS_DATE_ADAPTER_OPTIONS =
  new InjectionToken<DayJsDateAdapterOptions>(
    'COS_DAYJS_DATE_ADAPTER_OPTIONS',
    {
      providedIn: 'root',
      factory: COS_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY,
    }
  );

export function COS_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY(): DayJsDateAdapterOptions {
  return {
    useUtc: true,
  };
}

const unitMap = {
  [DateUnit.Year]: 'year',
  [DateUnit.Month]: 'month',
  [DateUnit.Day]: 'day',
  [DateUnit.Week]: 'week',
} as const;

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
@Injectable()
/** Adapts Dayjs Dates for use with Angular Material. */
export class DayjsDateAdapter extends DateAdapter<Dayjs> {
  private _localeData!: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };
  private _options = inject<DayJsDateAdapterOptions>(
    COS_DAYJS_DATE_ADAPTER_OPTIONS,
    { optional: true }
  );

  constructor() {
    const dateLocale = inject<string>(COS_DATE_LOCALE, { optional: true });

    super();
    this.setLocale(dateLocale || dayjs.locale());
  }

  override setLocale(locale: string) {
    super.setLocale(locale);

    dayjs.locale(locale);
    const dayjsLocaleData = dayjs.localeData();
    this._localeData = {
      firstDayOfWeek: dayjsLocaleData.firstDayOfWeek(),
      longMonths: dayjsLocaleData.months(),
      shortMonths: dayjsLocaleData.monthsShort(),
      dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: dayjsLocaleData.weekdays(),
      shortDaysOfWeek: dayjsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayjsLocaleData.weekdaysMin(),
    };
  }

  getYear(date: Dayjs): number {
    return this.clone(date).year();
  }

  getMonth(date: Dayjs): number {
    return this.clone(date).month();
  }

  getDate(date: Dayjs): number {
    return this.clone(date).date();
  }

  getDayOfWeek(date: Dayjs): number {
    return this.clone(date).day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // Dayjs doesn't support narrow month names, so we just use short if narrow is requested.
    return style == 'long'
      ? this._localeData.longMonths
      : this._localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this._localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }

  getYearName(date: Dayjs): string {
    return this._dayJs(date).format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return this._localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: Dayjs): number {
    return this._dayJs(date).daysInMonth();
  }

  clone(date: Dayjs): Dayjs {
    return date.clone();
  }

  createDate(year: number, month: number, date: number): Dayjs {
    // Dayjs will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (ngDevMode) {
      if (month < 0 || month > 11) {
        throw Error(
          `Invalid month index "${month}". Month index has to be between 0 and 11.`
        );
      }

      if (date < 1) {
        throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
      }
    }
    const returnDayjs = this._dayJs({ year, month, date });

    if (ngDevMode) {
      // If the result isn't valid, the date must have been out of bounds for this month.
      const modified = returnDayjs.set({
        year,
        month,
        date,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      // Check that the date wasn't above the upper bound for the month, causing the month to overflow
      if (modified.month() != month) {
        throw Error(`Invalid date "${date}" for month with index "${month}".`);
      }
    }

    return returnDayjs;
  }

  today(): Dayjs {
    return this._dayJs();
  }

  parse(value: any, parseFormat = ''): Dayjs | null {
    if (value && typeof value === 'string') {
      return this._dayJs(value, parseFormat);
    }

    return value ? this._dayJs(value).locale(this.locale) : null;
  }

  format(date: Dayjs, displayFormat: string): string {
    if (
      !this.isValid(date) &&
      (typeof ngDevMode === 'undefined' || ngDevMode)
    ) {
      throw Error('DayjsDateAdapter: Cannot format invalid date.');
    }
    if (!dayjs.isDayjs(date)) {
      date = this._dayJs(date);
    }
    return date.locale(this.locale).format(displayFormat);
  }

  addCalendarYears(date: Dayjs, years: number): Dayjs {
    return date.add(years, 'year');
  }

  addCalendarMonths(date: Dayjs, months: number): Dayjs {
    return date.add(months, 'month');
  }

  addCalendarDays(date: Dayjs, days: number): Dayjs {
    return date.add(days, 'day');
  }

  toIso8601(date: Dayjs): string {
    return date.toISOString();
  }

  getAbsoluteUTCDate(date: Dayjs): Dayjs {
    return dayjs.unix(
      Date.UTC(
        date.year(),
        date.month(),
        date.date(),
        date.hour(),
        date.minute(),
        date.second(),
        date.millisecond()
      ) -
        date.utcOffset() * 60000
    );
  }

  override getStartOf(date: Dayjs, unit: DateUnit): Dayjs {
    return date.startOf(unitMap[unit]);
  }

  override getEndOf(date: Dayjs, unit: DateUnit): Dayjs {
    return date.endOf(unitMap[unit]);
  }

  /**
   * Returns the given value if given a valid Dayjs or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Dayjs and empty
   * string into null. Returns an invalid date for all other values.
   */
  override deserialize(value: any): Dayjs | null {
    let date;
    if (value instanceof Date) {
      date = this._dayJs(value);
    } else if (this.isDateInstance(value)) {
      // NOTE: assumes that cloning also sets the correct locale.
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = this._dayJs(value, undefined, false);
    }
    if (date && this.isValid(date)) {
      return date;
    }
    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  isValid(date: ConfigType): boolean {
    if (dayjs.isDayjs(date)) {
      return date.isValid();
    }
    return this._dayJs(date).isValid();
  }

  invalid(): Dayjs {
    return this._dayJs(null);
  }

  private _dayJs(
    input?: ConfigType,
    format?: string,
    keepLocalTime = true
  ): Dayjs {
    const { strict, useUtc }: DayJsDateAdapterOptions = this._options || {};

    const method = (...args: any[]) =>
      useUtc ? dayjs(...args).utc(keepLocalTime) : dayjs(...args);
    let parsed = method(input, format, strict);

    // dayjs strictly follows the provided format
    // so partial strings will not be resolved. in this case attempt to resolve without formatting
    if (!parsed?.isValid() && !strict && format) {
      parsed = method(input, undefined, strict);
      if (ngDevMode) {
        console.warn(
          `Failed to parse "${input}" as "${JSON.stringify(
            format
          )}". Resolved without format to "${parsed}"`
        );
      }
    }
    return parsed;
  }
  getTimezoneAbbreviation(date: Dayjs): string {
    return getTimezoneAbbreviation(date);
  }

  parseAndSetTime(date: Dayjs | null, time: string | null) {
    return parseAndSetTime(date, time);
  }
}
